<template>
	<form class="form-horizontal" @submit.prevent="save">
		<div class="animated fadeIn">
			<b-card>
				<div class="display-table full-width mb-2">
					<strong class="card-title">{{$route.meta.label}}</strong>
				</div>
				<b-row>
					<ResponseAlert ref="response" />
					<b-col sm="8" class="mb-2">
						<b-form-group v-bind:class="{ 'is-invalid': errors.has('title')}" label="Title">
							<b-form-input v-model="title" v-validate="'required'" data-vv-name="title">
							</b-form-input>
							<div v-if="errors.has('title')">
								<p class="text-danger">{{ errors.first('title') }}</p>
							</div>
						</b-form-group>
						<b-form-group v-bind:class="{ 'is-invalid': errors.has('slug')}" label="Slug">
							<b-form-input v-model="models.slug" v-validate="'required'" data-vv-name="slug" disabled>
							</b-form-input>
							<div v-if="errors.has('slug')">
								<p class="text-danger">{{ errors.first('slug') }}</p>
							</div>
						</b-form-group>

						<div class="my-3" v-if="$route.fullPath.toLowerCase().includes('video')">
							<div class="d-flex justify-content-center">
							</div>

							<div v-if="vars.videoSrc" class="d-flex justify-content-center">
								<video ref="frame" v-if="isMp4" height="240" controls :src="vars.videoSrc">
								</video>
								<iframe ref="frame" v-else width="500" height="340" :src="vars.videoSrc">
								</iframe>
							</div>
							<div>
								<b-button v-if="!vars.videoUrlActive" block class="btn btn-primary text-white" @click="vars.videoUrlActive = !vars.videoUrlActive">
									Add Video URL
								</b-button>
								<div v-else ref="url_yt">
									<b-input-group  class="mt-3">
										<b-form-input placeholder="Insert video url..." v-model="models.video"></b-form-input>
										<b-input-group-append>
											<b-button @click.prevent="getSrcVideo" variant="primary">Show Preview</b-button>
											<b-button variantawait ="outline-danger" @click="vars.videoUrlActive = !vars.videoUrlActive; vars.videoSrc = null; models.video = null;">Cancel</b-button>
										</b-input-group-append>
									</b-input-group>
								</div>
							</div>
						</div>

						<div class="my-3">
							<uploader :value="models.galleries" @get-files="files => models.galleries = files"/>
						</div>

						<b-form-group v-bind:class="{ 'is-invalid': errors.has('description')}" label="Description">
							<Editor @getAdsState="value => vars.isAdsEmbed = value" :embedAds="embedAds" :value.sync="models.description" @input="value => models.description = value"/>
							<div v-if="errors.has('description')">
								<p class="text-danger">{{ errors.first('description') }}</p>
							</div>
						</b-form-group>
					</b-col>
					<b-col sm="4" class="mb-2">
						<div class="image-input">
							<b-img :src="getUrlOfFile(models.thumbnail)" class="mb-2" style="max-height: 160px; border: none"/>
							<b-button class="btn-upload btn-block" @click="$refs.filemanager.show('thumbnail')">
									<i class="fa fa-upload"></i> Upload Thumbnail
							</b-button>
						</div>
						<b-form-group class="mt-3" v-bind:class="{ 'is-invalid': errors.has('thumbnail title')}" label="Thumbnail Title">
							<b-form-input v-model="models.thumbnail_title" v-validate="vars.thumbnailTitleRules" data-vv-name="thumbnail title">
							</b-form-input>
							<div v-if="errors.has('thumbnail title')">
								<p class="text-danger">{{ errors.first('thumbnail title') }}</p>
							</div>
						</b-form-group>
						<b-form-select v-model="models.category_id" :options="masters.categories"></b-form-select>
						<b-form-checkbox
							id="breaking-news"
							class="my-4"
							v-model="models.is_breaking_news"
							name="breaking-news"
							:value="true"
							:unchecked-value="false"
						>
							Breaking News
						</b-form-checkbox>
						<b-form-checkbox
							id="editors-choice"
							class="my-4"
							v-model="models.is_editor_choice"
							name="editors-choice"
							:value="true"
							:unchecked-value="false"
						>
							Editor's Choice
						</b-form-checkbox>
						<b-form-checkbox
							id="top-stories"
							class="my-4"
							v-model="models.is_top_stories"
							name="top-stories"
							:value="true"
							:unchecked-value="false"
						>
							Top Stories
						</b-form-checkbox>
						<div id="datepicker-date-wrapper" class="mb-3">
                            <label for="datepicker-date">Date</label>
                            <div class="d-flex flex-column">
                                <b-form-datepicker 
                                    id="datepicker-date" 
                                    v-model="models.date"
                                    placeholder="dd/mm/yyyy"
                                    :date-format-options="{  day: '2-digit',  month: '2-digit', year: 'numeric' }"
                                    locale="id"
                                    class="custom-datepicker"
                                    @input="handleDateChange('date')"
                                />
                                <b-form-timepicker 
                                    v-model="time.date"
                                    placeholder="--:--"
                                    :hour12="false"
                                    class="custom-datepicker mt-2"
                                />
                            </div>
						</div>
						<div id="datepicker-publishAt-wrapper" class="mb-3">
                            <label for="datepicker-publishAt">Publish At</label>
                            <div class="d-flex flex-column">
                                <b-form-datepicker 
                                    id="datepicker-publishAt" 
                                    v-model="models.publish_at"
                                    placeholder="dd/mm/yyyy"
                                    :date-format-options="{  day: '2-digit',  month: '2-digit', year: 'numeric' }"
                                    :min="vars.minDate"
                                    locale="id"
                                    class="custom-datepicker"
                                    @input="handleDateChange('publish_at')"
                                />
                                <b-form-timepicker 
                                    v-model="time.publish_at"
                                    placeholder="--:--"
                                    :hour12="false"
                                    class="custom-datepicker mt-2"
                                />
                            </div>
						</div>
						<b-form-textarea
							class="mb-3"
							v-model="models.meta.keyword"
							placeholder="Meta Keyword"
							rows="5"
							max-rows="10"
						></b-form-textarea>
						<b-form-textarea
							class="mb-3"
							v-model="models.meta.description"
							placeholder="Meta Description"
							rows="5"
							max-rows="10"
						></b-form-textarea>
						<b-form-group v-bind:class="{ 'is-invalid': errors.has('tag')}" label="Tag">
							<multiselect 
								style="margin-left: -10px" 
								class="bg-white" 
								tag-placeholder="" 
								placeholder="Tag" 
								:showPointer="false"
								v-model="masters.tags"
								:hideSelected="true"
								:showNoResults="false"
								:options="masters.tags" 
								:multiple="true" 
								:taggable="true" 
								:closeOnSelect="false"
								@tag="addTag">
							</multiselect>
						</b-form-group>
						<b-form-checkbox
							v-model="models.is_paid"
							:value="true"
							:unchecked-value="false"
							class="mb-3"
						>
							Paid News
						</b-form-checkbox>
						<b-form-checkbox
							id="advertisement"
							class=""
							v-model="vars.isAds"
							name="advertisement"
							:value="true"
							:unchecked-value="false"
						>
							Add Advertisement
						</b-form-checkbox>
						<div v-if="vars.isAds" class="mb-4">
							<b-form-select class="mt-3" v-model="models.advertisement_id" :options="masters.ads"></b-form-select>
							<small v-if="showRequiredAds" class="text-danger text-center">*Please select an advertisement</small>
							<b-form-select class="mt-3" v-model="models.position" :options="masters.ads_position"></b-form-select>
							<small v-if="!showRequiredAds && models.position === 'body' && vars.isAdsEmbed" class="text-danger text-center">*Add the "[ADS]" tag in the description column according to the ad position you want</small>
						</div>
						<b-form-group class="mt-4" v-if="models.status !== 'waiting review' && models.status !== 'reject'">
							<b-form-radio-group
								v-model="models.status"
								name="status"
							>
								<b-form-radio value="publish">Publish</b-form-radio>
								<b-form-radio value="draft">Draft</b-form-radio>
							</b-form-radio-group>
						</b-form-group>
					</b-col>
				</b-row>
				<template #footer>
					<b-button :disabled="hasEmbedAds" type="submit" variant="primary" class="btn-min-width rounded float-left">
						Save
					</b-button>
					<b-button type="button" variant="light" class="btn-min-width rounded float-left" @click="$router.push({name: config.uri})">
						Cancel
					</b-button>
				</template>
			</b-card>
		</div>
		<Filemanager ref="filemanager" name="news" @selectMedia="selectMedia"/>
	</form>
</template>
<script>
	import api from '@/utils/api.js'
	import moment from 'moment'
	import {
		getUrlOfFile,
		str_slug
	} from '@/utils/helpers.js';
	import Editor from '@/components/editor';
	import Filemanager from '@/components/file_manager';
	import ResponseAlert from '@/components/response_alert';
	import getYoutubeId from 'get-youtube-id'
	import Uploader from '../../../components/uploader.vue';

	export default {
		name: 'FormNews',
		components: {
			ResponseAlert,
			Uploader,
			Filemanager,
			Editor
		},
		data() {
			return {
				config: {
					uri: this.$route.meta.permission.link,
				},
                time: {
                    date: '',
                    publish_at: '',
                },
				models: {
					type: this.$route.meta.type,
					title: '',
					slug: '',
					category_id: null,
					date: '',
					publish_at: '',
					thumbnail_title: '',
					thumbnail: '',
					galleries: [],
					video: '',
					description: '',
					is_breaking_news: false,
                    is_editor_choice: false,
					is_contributor_news: false,
                    is_top_stories: false,
					meta: {
							description:"",
							keyword: "",
							tag:""
					},
					status: "publish",
					advertisement_id: null,
					position: '',
					is_paid: false
				},
				editorOption: {
					modules: {
						toolbar: [
              ['bold', 'italic', 'underline', 'strike'],
              [{ 'header': 1 }, { 'header': 2 }],
              [{ 'list': 'ordered'}, { 'list': 'bullet' }],
              ['link', 'image'],
              ['clean']
						],
					}
				},
				masters: {
					categories: [
						{
							value: null,
							text: 'Select Category'
						}
					],
					ads: [
						{
							value: null,
							text: 'Select Advertisement'
						}
					],
					ads_position: [
						{
							value: '',
							text: 'Ads Position'
						},
						{
							value: 'popup',
							text: 'Popup'
						},
						{
							value: 'banner',
							text: 'Banner'
						},
						{
							value: 'body',
							text: 'Body'
						},
						{
							value: 'footer',
							text: 'Footer'
						},
						{
							value: 'giant_banner',
							text: 'Giant Banner'
						},
					],
					tags: []
				},
				vars: {
					FormData: new FormData(),
					role_password: 'required|min:8',
					thumbnailTitleRules: '',
					videoSrc: null,
					videoUrlActive: false,
					isAds: false,
					minDate: moment().format('YYYY-MM-DDTHH:mm'),
					embedAdsProps: false,
					isAdsEmbed: false
				},
				moment
			}
		},
		watch: {
			thumbnail() {
				this.vars.thumbnailTitleRules = this.thumbnail === '' ? '' : 'required'
			}
		},
		created() {
			let _ = this
			Promise.all([
				_.getById(),
				_.getAds(),
				_.getCategories()
			]).then(() => {
				this.$watch('ads', () => {
					this.vars.embedAdsProps = this.models.advertisement_id && this.models.position === 'body'
				})
			})
		},
		methods: {
			getById() {
				const _ = this;
				if (_.$route.params.id) {
					_.vars.minDate = ''
					_.$axios.get(`${api.news}/${_.$route.params.id}`)
						.then(res => {
							const { data } = res.data;
							_.models.type = data.type
							_.models.title = data.title
							_.models.slug = data.slug
							_.models.thumbnail_title = data.thumbnail_title
							_.models.category_id = data.category_id
							_.models.date = moment(data.date).format('YYYY-MM-DD')
                            _.time.date = moment(data.date).format('HH:mm')
							_.models.publish_at = moment(data.publish_at).format('YYYY-MM-DD')
                            _.time.publish_at = moment(data.publish_at).format('HH:mm')
							_.models.thumbnail = data.thumbnail
							_.models.galleries = data.galleries
							_.models.video = data.video || ''
							_.models.description = data.description
							_.models.is_breaking_news = data.is_breaking_news
							_.models.is_editor_choice = data.is_editor_choice
							_.models.is_top_stories = data.is_top_stories
							_.models.is_paid = data.is_paid
							_.models.is_contributor_news = data.is_contributor_news
							_.models.status = data.status

							if(Object.entries(data.meta).length > 0) {
								_.models.meta.description = data.meta.description || ''
								_.models.meta.keyword = data.meta.keyword || ''
								_.masters.tags = data.meta.tag === '' ? [] : data.meta.tag.split(",")
							}

							if(data.news_advertisement) {
								_.vars.isAds = true
								_.models.advertisement_id = data.news_advertisement.advertisement_id,
								_.models.position = data.news_advertisement.position
							}

							if(_.models.video) {
								_.vars.videoUrlActive = true
								_.getSrcVideo()
							} 
						}).catch(() => _.$router.push({
							name: 'page404'
						}));
				}
			},
			getCategories() {
				const _ = this
				_.$axios.get(api.news_category, {
					params: {
						status: 'active',
						orderby: 'name',
						sort: 'asc',
					}
				}).then(res => {
					const {rows} = res.data.data
					rows.forEach(row => {
						_.masters.categories.push({
							value: row.id,
							text: row.name
						})
					})
				});
			},
			getAds() {
				const _ = this
				_.$axios.get(api.advertisement, {
					params: {
						status: 'publish',
						orderby: 'name',
						sort: 'asc',
					}
				}).then(res => {
					const {rows} = res.data.data
					rows.forEach(row => {
						_.masters.ads.push({
							value: row.id,
							text: row.name
						})
					})
				});
			},
			selectMedia(field, media) {
				const _ = this;
				_.models[field] = media.file;
				_.models[`${field}_title`] = media.title
			},
			getUrlOfFile(file) {
				return getUrlOfFile(file, true);
			},
			getSrcVideo() {
				const fileUrl = this.models.video
				if(fileUrl.toLowerCase().includes('youtube.com')) {
					this.vars.videoSrc = 'https://www.youtube.com/embed/' + getYoutubeId(this.models.video)
				} else {
					this.vars.videoSrc = this.models.video
				}
			},
			addTag (newTag) {
				this.masters.tags.push(newTag)
			},
			save() {
				const _ = this;
				_.$validator.validate().then(valid => {
					if (valid) {
						let request = "";
						let message = "create"

						_.models.meta.tag = _.masters.tags.join(',')

						if(!this.vars.isAds) {
							this.models.advertisement_id = null
							this.models.position = ''
						}

                        _.models.date = this.formatedDate('date')
                        _.models.publish_at = this.formatedDate('publish_at')

						if (!_.$route.params.id) {
							request = _.$axios.post(api.news, _.models);
						} else {
							message = "update"
							request = _.$axios.put(`${api.news}/${_.$route.params.id}`, _.models);
						}
						request
							.then(() => {
								this.$showToast('Success', `Success ${message} news`)
								_.$router.push({
									name: _.config.uri
								})
							})
							.catch(error => _.$refs.response.setResponse(error.response.data.message, 'danger'));
					} else {
						window.scrollTo(0, 0);
					}
				});
			},
            handleDateChange(type) {
                if (!this.$route.params.id) {
                    this.time[type] = moment().format('HH:mm:ss');
                }
            },
            formatedDate(type) {
                if (this.models[type]) {
                    const datePart = moment(this.models[type]).format('YYYY-MM-DD');
                    const timePart = moment(this.time[type], 'HH:mm').format('HH:mm');
                    return `${datePart}T${timePart}`;
                }
                return ''
            }
		},
		computed: {
			isMp4(){
				return this.vars.videoSrc.substring(this.vars.videoSrc.lastIndexOf(".") + 1) === 'mp4'
			},
			title: {
				get(){
					return this.models.title
				},
				set(value){
					this.models.title = value
					this.models.slug = str_slug(value)
				}
			},
			thumbnail() {
				return this.models.thumbnail
			},
			embedAds () {
				return this.vars.embedAdsProps
			},
			ads() {
				return `${this.models.position} ${this.models.advertisement_id}`
			},
			showRequiredAds() {
				return !this.embedAds && this.models.position === 'body'
			},
			hasEmbedAds() {
				if(this.vars.isAds && this.models.position === 'body') {
					return this.vars.isAdsEmbed || !this.models.advertisement_id || !this.models.description.includes('[ADS]')
				}
				return false
			}
		}
	}
</script>