<template>
  <div id="uploader-wrapper">
    <div v-if="files.length > 0" class="items-wrapper">
      <div v-for="file, index in files" :key="index" class="item-wrapper">
        <b-img :src="getUrlOfFile(file.image)" class="mb-2" alt="image-items"/>
        <b-button @click.prevent="remove(file.id)" size="sm" class="btn btn-danger rounded-circle">
          <i class="fa fa-trash"></i>
        </b-button>
      </div>
    </div>

    <b-button class="btn btn-primary text-white px-5 m-auto d-block" @click="$refs.filemanager.show('image')">
      Add Photo
    </b-button>
		<Filemanager ref="filemanager" name="photos" @selectMedia="selectMedia"/>
  </div>
</template>

<script>
import {
		getUrlOfFile
	} from '@/utils/helpers.js';
	import Filemanager from '@/components/file_manager';

export default {
  components: {
    Filemanager
  },
  props: {
      value: {
          type: Array,
          // eslint-disable-next-line vue/require-valid-default-prop
          default: [],
      },
  },
  data() {
    return {
      files: [],
    }
  },
  methods: {
    selectMedia(field, media) {
      const _ = this;
      _.files = []
      media.forEach(item => {
        _.files.push({
          id: item.id,
          image: item.file,
          description: item.description
        })
      })
      _.$emit('get-files', _.files)
    },
    remove(id){
      const _ = this
      _.files = _.files.filter(file => file.id !== id)
      _.$emit('get-files', _.files)
    },
    getUrlOfFile(file) {
      return getUrlOfFile(file, true);
    },
  },
  watch: {
    value() {
      this.files = this.value
    }
  }
}
</script>

<style scoped>
.items-wrapper {
  display: flex;
  flex-wrap: nowrap;
  overflow-x: auto;
  padding: 20px 0;
  margin-bottom: 20px;
}

.items-wrapper img:last-child {
  margin-right: 0;
}

.item-wrapper {
  position: relative;
  margin-right: 25px;
  height: 150px;
}

.item-wrapper img {
  flex: 0 0 auto;
  height: 100%;
}

.items-wrapper button {
  position: absolute;
  top: -10px;
  right: -10px;
}
.items-wrapper::-webkit-scrollbar {
  height: 5px;      
  border-radius: 15px;
}

.items-wrapper::-webkit-scrollbar-track {
  border-radius: 15px;    /* width of the entire scrollbar */
  background: lightgray;        /* color of the tracking area */
}

.items-wrapper::-webkit-scrollbar-thumb {
  background: rgb(180, 180, 180);
  border-radius: 20px;       /* roundness of the scroll thumb */
  border: 3px solid rgb(180, 180, 180);  /* creates padding around scroll thumb */
}
</style>